import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PlatformWidget from '../components/platformWidget'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const Footer = loadable(() => import('../components/footer/footer'))

const PlatformsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle = 'ترددات قناة الشرق الجديدة | الشرق للأخبار '
  const seoDescription =
    ' ترددات قناة الشرق  (تردد قناة الشرق نايل سات)و (تردد قناة الشرق عرب سات) و (تردد قناة الشرق هوت بيرد) شاهد آخر الاخبار وكل ما هو جديد عل قناة الشرق للأخبار.'

  const canonicalUrl = `${process.env.GATSBY_BASE_URL}platforms/`

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <>
      <Layout location={location} title={siteTitle} lang={'ar'}>
        <h1 className="customH1">{'ترددات قناة الشرق'}</h1>
        <SEO
          title={seoTitle}
          lang="ar"
          description={seoDescription}
          meta={[
            {
              property: `keywords`,
              content: `ترددات قناة الشرق , تردد قناة الشرق نايل سات , تردد قناة الشرق عرب سات , تردد قناة الشرق هوت بيرد`
            }
          ]}
          canonicalUrl={canonicalUrl}
        />
        <PlatformWidget />
        <Footer />
      </Layout>
    </>
  )
}

export default PlatformsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
